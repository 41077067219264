import axios from "../axios";
import API_LOTTO12 from "../../endpoints/lotto12";
import _ from "lodash";
import {hideLoading, showLoading} from "react-redux-loading-bar";

import {
    LOTTO12_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT, LOTTO12_REQUEST_FETCH_RECENT_DRAW,
    LOTTO12_REQUEST_FETCH_RESULT_REPORT,
    LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO12_REQUEST_LIST_BETTING,
    LOTTO12_SET_BET_STATUS,
    LOTTO12_SET_RENDER_RESULT_STATUS,
    LOTTO12_SET_TOTAL_RESULT_NUMBER,
    LOTTO12_STATISTIC,

} from "./constant";
import {PAGINATION} from "../rootConstant";
import {getRecentSize} from "../user/action";

export const setBetStatus = (payload) => {
    return {
        type: LOTTO12_SET_BET_STATUS,
        payload: payload,
    };
};

export const setRenderResultStatus = (payload) => {
    return {
        type: LOTTO12_SET_RENDER_RESULT_STATUS,
        payload: payload,
    };
};

export const setResultTotalNumberKeno = (payload) => {
    return {
        type: LOTTO12_SET_TOTAL_RESULT_NUMBER,
        payload: payload,
    };
};

export const fetchLotto12ListBetting = (payload) => (dispatch) => {

    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12") || "[]");

    let item = {
        betList: betting,
        summary: {
            total: 0,
            currency: "",
            currencySignal: "",
        },
    };
    if (betting?.length > 0)
        item.summary.total = _.sumBy(betting, 'amount');

    if (payload.isClear) {
        return dispatch({
            type: LOTTO12_REQUEST_LIST_BETTING,
            payload: item,
        });
    }
    return item.summary.total;
};

export const addLotto12Betting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12"));
    if (_.isEmpty(betting)) betting = [];
    betting.push(payload);
    sessionStorage.setItem("betting_lotto12", JSON.stringify(betting))
    dispatch(fetchLotto12ListBetting({isClear: true}));
};

export const deleteLotto12Betting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12"));
    betting.splice(payload.id, 1);
    sessionStorage.setItem("betting_lotto12", JSON.stringify(betting))
    dispatch(fetchLotto12ListBetting({isClear: true}));
};

export const clearCartLotto12 = () => (dispatch) => {
    sessionStorage.removeItem("betting_lotto12");
    dispatch(fetchLotto12ListBetting({isClear: true}));
};

export const lotto12ConfirmBettingNow = (callback) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto12"));
    const payload = {
        "drawCode": "",
        "gameType": "LOTTO12",
        "items": []
    };

    if (betting?.length > 0) {
        payload.drawCode = betting[betting.length - 1].drawCode;
        payload.items = betting;

    }
    callback();

    return axios
        .post(API_LOTTO12.LOTTO12_BETTING_NOW, payload)
        .then(async (response) => {
            await sessionStorage.removeItem("betting_lotto12");
            await dispatch(fetchLotto12ListBetting({isClear: true}));
            return Promise.resolve(response);
        })
        .catch((error) => {
            // remove session storage when it error
            sessionStorage.removeItem("betting_lotto12");
            return Promise.reject(error);
        });

};

export const fetchLotto12DrawingResult = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12_DRAWING_RESULT);
        let data = response.data.data;
        // let data = drawing12;
        if (data !== null) {
            const timeCount = data.countDownTimer;
            if (data.oldResult != null && timeCount <= 0) {
                data.isStopRender = false;
                data.isStopBet = true;
            }

            if (timeCount > 9) {
                data.isStopBet = false;
                data.isStopRender = true;
            }
            dispatch({
                type: LOTTO12_REQUEST_FETCH_DRAWING_RESULT,
                payload: data
            });
        }
        return response;
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLotto12WinLossReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios.get(API_LOTTO12.FETCH_LOTTO12_WIN_LOSS_REPORT, {
        params: {filterByLotto: "LOTTO12", ...payload}
    })
        .then((response) => {
            const paging = response.data.paging;
            const data = response.data.data;

            dispatch({
                type: LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT,
                payload: {
                    tickets: data.tickets,
                    summary: data.summary,
                    filter: payload,
                    ...paging
                },
            });

            dispatch(hideLoading("sectionBar"));

        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

export const fetchLotto12ResultReport = (payload) => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12_RESULT_REPORT, {params: {size: PAGINATION.SIZE, page: 1, ...payload}});
        const paging = response.data.paging;

        dispatch({
            type: LOTTO12_REQUEST_FETCH_RESULT_REPORT,
            payload: {
                items: response.data.data,
                filter: payload,
                ...paging
            },
        });

    } catch (error) {
        return await Promise.reject(error);
    }

};

export const fetchLotto12RecentBetReport = () => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12_RECENT_BET_REPORT, {
                params: {
                    size: getRecentSize().lo12,
                    page: 1,
                    filterByLotto: "LOTTO12",
                    groupByDraw: "YES",
                }
            });

        dispatch({
            type: LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });

        return await Promise.resolve(response);

    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLotto12RecentBetReportCache = () => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LOTTO12.FETCH_LOTTO12_RECENT_BET_REPORT_CACHE, {
                params: {
                    size: getRecentSize().lo12,
                    page: 1,
                    filterByLotto: "LOTTO12",
                    groupByDraw: "YES",
                }
            });

        dispatch({
            type: LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });

        return await Promise.resolve(response);

    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchStatistic12 = (payload) => async (dispatch) => {
    const filter = {row: 6, column: 16, skip: 1, ...payload};
    try {
        const response = await axios.get(API_LOTTO12.FETCH_LOTTO12_STATISTIC, {
            params: filter
        });
        const data = response.data.data;
        dispatch({
            type: LOTTO12_STATISTIC,
            payload: data
        });
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLotto12RecentDraw = () => (dispatch) => {
    return axios
        .get(API_LOTTO12.LOTTO12_FETCH_RECENT_DRAW)
        .then((response) => {
            dispatch({
                type: LOTTO12_REQUEST_FETCH_RECENT_DRAW,
                payload: response.data.data
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
