import DatetimeContainer from "../date/DatetimeContainer";
import React, {useEffect, useRef} from "react";
import Lottie from "react-lottie";
import RebateRate from "../../pages/components/RebateRate";
import cardSmallAudio from "../../assets/audios/card-small.mp3";
import {useDispatch} from "react-redux";
import {
    setPlayAudio,
} from "../../store/rootAction";
import CountDownTimer from "../timer/CountDownTimer";
import TableStatistic from "./TableStatistic";
import ColorItems from "./mix/ColorItems";
import {getColorNumbers, isDisabledODEO, pfType} from "../../constants/general";
import {cardAudioUrl, isKp, lotto12CardUrl} from "../../utils/asset";
import {getCardVersion} from "../../utils/cached";
import LoadingGif from "../../utils/LoadingGif";

export default (props) => {
    const {
        t, oldResult, isStopped, isStartFlip, setIsStartFlip, config: {
            lotto12Config
        },
        winUnderOver,
        winOddEven,
        winNumber,
        winColor,
    } = props;
    const jackpot = oldResult?.jackpot;
    const isShow = (oldResult?.numberCode === oldResult?.jackpotType) && oldResult?.isJackpot;
    const startDrawing = useRef(null);
    const platformType = pfType;

    let lottoDraw = oldResult?.lottoDraw;
    const dispatch = useDispatch();
    const playCardAudio = (src) => {
        dispatch(setPlayAudio(src));
    };

    // init 11 cards arrays
    let list = Array(11).fill();

    const clearInv = () => {
        startDrawing.current && clearInterval(startDrawing.current);
    }

    useEffect(() => {
        if (isStartFlip && lottoDraw) {
            clearInv();
            if (isStopped) {
                // get index drawing result
                let drawingItem = lottoDraw.items.filter(e => e.isDrawRelease === 1);
                let index = lottoDraw.items.indexOf(drawingItem[0]);

                // set 11 card images
                lottoDraw.items.forEach((e, i) => {
                    if (e.isDrawRelease === 0) {
                        let doc = document.getElementById(`card-inner-${i}`);
                        if (doc) doc.classList.add("flip-180");
                        if (i === 10) {
                            let doc = document.getElementById(`card-inner-result`);
                            if (doc) doc.classList.add("flip-180");
                        }
                    }
                    let img = document.getElementById(`img-${i}`);
                    if (img) img.src = lotto12CardUrl(e?.numberCode);
                });

                // set image result
                let imgResult = document.getElementById(`img-result`);
                if (imgResult) imgResult.src = lotto12CardUrl(lottoDraw?.resultNumber);

                startDrawing.current = setInterval(() => {
                    let doc = document.getElementById(`card-inner-${index}`);

                    if (doc && !doc.classList.contains("flip-180")) {
                        playCardAudio(cardSmallAudio)
                        doc.classList.add("flip-180");
                    }
                    if (index >= 10) {
                        // flip result card
                        let doc = document.getElementById(`card-inner-result`);

                        if (doc) doc.classList.add("flip-180");
                        !isKp() && playCardAudio(cardAudioUrl(lottoDraw?.resultNumber));
                        let setWin = setTimeout(() => {
                            // playCardAudio(winSound)
                            props.setWinLose();
                            clearTimeout(setWin);
                        }, 1000);
                        clearInv();
                        setIsStartFlip(false);

                    }
                    index++;

                }, 1400)

            }
        } else {
            clearInv();
        }

        return () => {
            clearInv();
        }
    })


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require(`../../assets/images/lotto12/${platformType}.json`),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return <div className="col no-padding-div none-select">
        <div className="row">

            {!isStopped ? <div className="col-6 no-padding-div lotto12-bg">
                    <div className="lotto12-betting-animation mt-8-vw">
                        <Lottie options={defaultOptions}
                                isStopped={isStopped}
                                isPaused={false}/>
                    </div>
                </div> :
                <div className="col-6 no-padding-div lotto12-bg">
                    <div className="mt-8-vw">
                        <div className="col-12 lotto12-mt-vw">
                            <div className="pl-3 pr-3 pt-3">
                                <div className="pl-3 pr-3 row justify-content-center">
                                    {
                                        list.map((_, i) => {
                                                return <div key={i} className="flip-card card-size no-padding-div">
                                                    <div className="flip-card-inner" id={`card-inner-${i}`}>
                                                        <div className="flip-card-front">
                                                            <img className="img-responsive"
                                                                 src={require(`../../assets/images/lotto12/${platformType}.png`)}
                                                                 alt=""/>
                                                        </div>
                                                        <div className="flip-card-back">

                                                            <img id={`img-${i}`} className="img-responsive"
                                                                 src={require(`../../assets/images/lotto12/${platformType}.png`)}
                                                                 alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <div className="row pt-3 justify-content-center">
                                <div className="no-padding-div">
                                    <div className="flip-card-inner " id="card-inner-result">
                                        <div className="flip-card-front">
                                            <img className="img-responsive"
                                                 src={require(`../../assets/images/lotto12/${platformType}.png`)}
                                                 alt=""/>
                                        </div>
                                        <div className="flip-card-back">
                                            {isShow &&
                                                <div className="jackpot-12-number">
                                                    {jackpot > 1 && <img
                                                        width={50}
                                                        height={50}
                                                        className="jp-animate"
                                                        src={require(`../../assets/images/jackpot/${jackpot}.png`)}
                                                        alt=""/>}
                                                </div>}
                                            <img id="img-result" className="img-responsive"
                                                 src={require(`../../assets/images/lotto12/${platformType}.png`)}
                                                 alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            <div className="col-6 no-padding-div lotto12-betting">

                <div className="col row">
                    <div className="col-3 lotto12-countdown-number">
                        <CountDownTimer countClass="text-center f-32 " {...props} />
                    </div>
                    <div
                        className="col-9 header-div-title header-text fade-in padding-div-right lotto12-new-result">
                                <span className="text-white draw-code">
                                    <LoadingGif width="30" height="30"/>
                                    {(props.newResult !== null && props.newResult.drawLabel) ? props.newResult.drawLabel : t("labels:upcoming_draw")}
                                </span>
                        <span className="text-white fade-in last-result-betting datetime"
                              id="timer-display"><DatetimeContainer/></span>
                    </div>
                </div>

                <div className="col-12 row">
                    <div className="col-6 div-body-white fade-in">
                        <div className="row">
                            {
                                lotto12Config.underOverOddEven.map((item, i) =>

                                    <div id={`card-${item.code}`}
                                         className={`col-6 no-padding-div pointer lotto6-box-bet lotto12-bet-pricing-card no-radius ${(item.code === winUnderOver || item.code === winOddEven) ? " color-animated" : ""}`}
                                         key={i}
                                         onClick={isDisabledODEO ? null : () => props.onBetting(item)}>
                                        <div className="slot-prefix-card">
                                            <div className={"lotto12-bet-" + item?.code}>
                                                <div
                                                    className="lotto12-bet-label">
                                                    <span>{t("labels:betPrefix." + item?.code)}</span></div>
                                                <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>
                                            </div>

                                        </div>
                                    </div>
                                )
                            }
                        </div>

                    </div>

                    <div className="col-6 div-body-white  fade-in">
                        <div className="row">
                            {
                                lotto12Config.colors.map((item, i) =>
                                    <div id={`card-${item.code}`}
                                         className={`col-4 no-padding-div lotto12-bet-pricing-card no-radius ${item.code === winColor ? "color-animated" : ""}`}
                                         key={i}
                                         onClick={() => props.onBetting(item)}>
                                        <div className="slot-prefix-card">
                                            <div className={"lotto12-bet-" + item?.code}>
                                                <div
                                                    className="lotto12-bet-label-color">{t("labels:betPrefix." + item?.code)}</div>
                                                <ColorItems values={getColorNumbers(item?.code)}/>
                                                <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-12 div-body-white fade-in">
                        <div className="row">
                            {
                                lotto12Config.numbers.map((item, i) =>
                                    <div id={`card-${item.code}`}
                                         className={`col-2 no-padding-div ${item.code === winNumber ? "color-animated" : ""}`}
                                         key={i}
                                         onClick={() => props.onBetting(item)}>
                                        <div>
                                            {(isShow && winNumber === item.code) &&
                                                <div className="jackpot-12-number">
                                                    {jackpot > 1 && <img id={item.code}
                                                                         width={30}
                                                                         className="jp-animate"
                                                                         height={30}
                                                                         src={require(`../../assets/images/jackpot/${jackpot}.png`)}
                                                                         alt=""/>}
                                                </div>}
                                            <img className="img-responsive"
                                                 src={lotto12CardUrl(item?.code)}
                                                 alt=""/>
                                            {getCardVersion() === "v1" &&
                                                <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* History drawing */}
        <TableStatistic/>

    </div>;
}
