import {ApolloClient, InMemoryCache} from "@apollo/client";
import {WebSocketLink} from "@apollo/client/link/ws";
import {env} from "../env";
import {io} from "socket.io-client";
import {getUser} from "../utils/cached";


const createApolloClient = () => {
    const accessToken = localStorage.getItem("accessToken");
    return new ApolloClient({
        link: new WebSocketLink({
            uri: env.SOCKET_URL,
            options: {
                reconnect: true,
                reconnectionAttempts: 5,
                connectionParams: {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            },
        }),
        cache: new InMemoryCache(),
    });
};

const initClientSocket = () => {
    const token = localStorage.getItem("accessToken");
    if (!token || !getUser()?.isSocket) return null;
    const sc = io(env.SOCKET_IO_URL, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        transports: ['websocket'],
        auth: {
            token: token
        }
    });
    return sc;
}

const socket = initClientSocket();

const removeSocketListener = (key) => {
    socket && socket.removeListener(key);
}


export {createApolloClient, socket, removeSocketListener};
