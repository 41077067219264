import React from "react";
import {withTranslation} from "react-i18next";
import BetCard from "./BetCard";
import {Form, Label, Row, UncontrolledTooltip} from "reactstrap";
import {Button} from "react-bootstrap";
import RowCard from "./RowCard";
import BetRQ from "./betModel";
import PNotify from "pnotify/dist/es/PNotify";
import {
    getMulti,
    getNormalRange,
    getNotPair,
    getPair, getSpecialRange, specialTripleX,
} from "../../utils/BetCalculateUtils";
import swal from "sweetalert";
import _ from "lodash";
import ReactToPrint from "react-to-print";
import {showErrorMessage} from "./showErrorMessage";
import RenderBettingItem from "./RenderBettingItem";
import DrawingResult from "./DrawingResultV2";
import {getAutoPrint} from "../../utils/Print";
import {clientAp} from "../../constants/general";
import {betTypes, multiples, post, SUBSCRIPTION_Lo5D} from "../../constants/lotto5d";
import {KEY_CODE} from "../../constants/Key";
import {ERROR_LOADING} from "../../store/lotto5d/types";
import {Ticket58} from "./ticket/ticket58";
import {getDateDef} from "../../utils/dateUtils";
import {getUser} from "../../utils/cached";
import Balance from "../../pages/components/Balance";
import InputNumber from "./InputNumber";
import {playAudio} from "../../store/audio/action";
import RecentTicket from "./RecentTicket";
import {getRecentSize} from "../../store/user/action";
import HistoryResult from "./v2/HistoryResult";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";


class SaleV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
            lastDraws: [],
            isSelected: null,
            autoPrint: true,
            isBetting: false,
            focusIndex: 0,
            disabled: false,
            isDeletePage: false,
            eventBus: undefined,
            posts: [...post],
            betTypes: {
                betType: [...betTypes],
                activeBetTypeId: "NORMAL",
            },
            pairStatus: 0,
            multiples: [...multiples],
            selectedMulti: false,
            currency: getUser()?.currencyCode,
            isMulti: false,
            specBetCheck: null,
            graveKey: false,
            fields: {
                betAmount: 0,
                multiDigit: null,
                numberFrom: "",
                numberTo: "",
                numberThree: "",
                numberFour: "",
            },
            currentBetting: {
                postGroup: [],
                totalUsd: 0,
                totalKhr: 0,
            }, data: props.sale.data, isRow: props.isRow, isLockBet: false,
            isSelectFrom: false,
            isSelectTo: false,
            isBetAmount: false,
            status: null,
        };
        this.handleBetTypeCheckChange = this.handleBetTypeCheckChange.bind(this);
        this.handlePostCheckChange = this.handlePostCheckChange.bind(this);
        this.handleCurrencyCheckChange = this.handleCurrencyCheckChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleDigitKeyPress = this.handleDigitKeyPress.bind(this);
        this.focusInputAmount = this.focusInputAmount.bind(this);
        this.unFocusInputAmount = this.unFocusInputAmount.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);

        this.firstDigitInput = React.createRef();
        this.twoDigitInput = React.createRef();
        this.threeDigitInput = React.createRef();
        this.fourDigitInput = React.createRef();
        this.amountDigitInput = React.createRef();
        this.componentRef = React.createRef();

    }

    componentDidMount() {
        this.subscription();
        this.refreshData();
        window.addEventListener("keydown", this.handleKeyDown);
        this.setState({autoPrint: getAutoPrint()});
        document.addEventListener("visibilitychange", this.handleTabChange);
    }

    onDrawStatusChange = (currentDraw) => {
        if (currentDraw.status !== this.state.status) {
            if (currentDraw.status === "OPEN" && this.state.status) {
                this.refreshData();
            }
            if (currentDraw.status === "RELEASE") this.props.fetchCurrentDraw5D();
            this.setState({status: currentDraw.status});
        }
    }

    refreshData = () => {
        this.props.fetchCurrentDraw5D();
        this.props.fetchResults({filterByDate: getDateDef()});
        this.props.fetchUserBalance();
        this.props.fetchLotto5dRecentTicket({size: getRecentSize().lo5d});
    }

    handleTabChange() {
        if (document.hidden) {
        } else {
            let doc = document.getElementById("refresh-draw");
            doc && doc.click()
        }
    }

    componentWillUnmount() {
        this.drawSubscription && this.drawSubscription.unsubscribe();
        this.removeListener();
        removeSocketListener(socketEvent.l5d);
    }


    subscription = () => {
        if (socketLottoConfig.l5d) {
            socket.on(socketEvent.l5d, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            this.drawSubscription = clientAp.subscribe({
                query: SUBSCRIPTION_Lo5D,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto5d_drawing) {
                        let currentDraw = event.data.v_lotto5d_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    };

    onFilter = (params = {}, callback) => {
        this.props.fetchBetting(params).then(() => callback && callback);
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        /* update props when prevProps changed */
        if (prevProps !== this.props) {
            this.setState(() => ({
                prevProps: this.props,
            }));
        }

        if (prevProps.isFocus !== this.props.isFocus) {
            if (!this.props.isFocus)
                window.addEventListener("keydown", this.handleKeyDown);
            else window.removeEventListener("keydown", this.handleKeyDown);
        }
        if (prevProps.isRow !== this.props.isRow) {
            this.setState((prevState) => ({
                ...prevState,
                isRow: this.props.isRow,
            }));
        }
        if (prevProps.drawing !== this.props.drawing) {
            if ((this.props.drawing.currentDraw && this.props.drawing.currentDraw.status)) {
                this.onClearAll();
                this.setState(pre => ({
                    currentBetting: {
                        postGroup: [],
                        totalUsd: 0,
                        totalKhr: 0,
                    },
                    fields: {
                        betAmount: 0,
                        multiDigit: null,
                        numberFrom: "",
                        numberTo: "",
                        numberThree: "",
                        numberFour: "",
                    }
                }))
            }
        }
    }


    /* removed event listener */
    removeListener = () => {
        window.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("visibilitychange", this.handleTabChange);
    };

    /* focus bet input */
    focusInputAmount() {
        this.setState({isFocus: true});
        const isMatrix = this.state.betTypes.activeBetTypeId === "MATRIX";
        switch (this.state.focusIndex) {
            case 1:
                this.twoDigitInput.current && this.twoDigitInput.current.focus();
                break;
            case 2:
                isMatrix
                    ? this.threeDigitInput.current && this.threeDigitInput.current.focus()
                    : this.amountDigitInput.current &&
                    this.amountDigitInput.current.focus();
                break;
            case 3:
                this.fourDigitInput.current && this.fourDigitInput.current.focus();
                break;
            case 4:
                this.amountDigitInput.current && this.amountDigitInput.current.focus();
                break;
            default:
                this.firstDigitInput.current && this.firstDigitInput.current.focus();
                break;
        }
    }

    /* unfocus bet input */
    unFocusInputAmount(isAll = false) {
        this.setState({isFocus: false});
        const isMatrix = this.state.betTypes.activeBetTypeId === "MATRIX";
        this.twoDigitInput.current && this.twoDigitInput.current.blur();
        this.threeDigitInput.current && this.threeDigitInput.current.blur();
        this.fourDigitInput.current && this.fourDigitInput.current.blur();
        this.amountDigitInput.current && this.amountDigitInput.current.blur();
        this.firstDigitInput.current && this.firstDigitInput.current.blur();
        if (isAll) {
            if (isMatrix) {
                this.firstDigitInput.current && this.firstDigitInput.current.blur();
                this.twoDigitInput.current && this.twoDigitInput.current.blur();
                this.threeDigitInput.current && this.threeDigitInput.current.blur();
                this.fourDigitInput.current && this.fourDigitInput.current.blur();
                this.amountDigitInput.current && this.amountDigitInput.current.blur();
            } else {
                this.firstDigitInput.current && this.firstDigitInput.current.blur();
                this.twoDigitInput.current && this.twoDigitInput.current.blur();
                this.amountDigitInput.current && this.amountDigitInput.current.blur();
            }
        }
    }

    /* handle edit post */
    onEditPost = (e) => {
        const item = e.postGroup;
        this.handlePostCheckChange("escape");
        let isLo = item.posts.includes("Lo");
        this.handlePostCheckChange(this.getPostEdited(item.posts));
        if (isLo) this.handlePostCheckChange("Lo");
        this.setState({postItemsTemp: e});
    };

    getPostEdited = (posts) => {
        let post = posts?.replaceAll("Lo", "").split("");
        if (post.filter(e => !isNaN(parseInt(e))).length) {
            post = post.filter(e => e !== "A");
            post = post.map((e => {
                if (e === "1")
                    e = "A1";
                if (e === "2")
                    e = "A2";
                if (e === "3")
                    e = "A3";
                if (e === "4")
                    e = "A4";
                return e;
            }));
        }
        return post;
    };

    /* handle on bet input change value  */
    onClickAmount = (p) => {
        let amount = this.state.fields.betAmount;
        if (amount === "") amount = 0;
        let betAmount = parseInt(amount);
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                betAmount: betAmount += p.value,
            },
        }));
    };

    /* handle on bet input change value  */
    onClickBetNumber = (value) => {
        let {
            focusIndex, fields: {
                numberTo,
                numberFrom,
                betAmount
            }, isSelectFrom,
            isSelectTo, isBetAmount
        } = this.state;

        if (!numberTo || isSelectTo) numberTo = "";
        if (!numberFrom || isSelectFrom) numberFrom = "";
        if (!betAmount || isBetAmount) betAmount = "";

        if (focusIndex === 1) {
            numberTo.length < 3 &&
            this.setState(pre => ({
                fields: {
                    ...pre.fields,
                    numberTo: `${numberTo}`.concat(`${value}`)
                },
                isSelectTo: false
            }));
        } else if (focusIndex === 4) {
            let amount = `${betAmount}`.concat(`${value}`);
            if (amount.length > 0 && parseInt(amount) === 0) return false;
            this.setState(pre => ({
                fields: {
                    ...pre.fields,
                    betAmount: `${betAmount}`.concat(`${value}`)
                },
                isBetAmount: false
            }));
        } else {
            numberFrom.length < 3 &&
            this.setState(pre => ({
                fields: {
                    ...pre.fields,
                    numberFrom: `${numberFrom}`.concat(`${value}`)
                },
                isSelectFrom: false
            }));
        }
    };

    onSubmitEditItem = (betItem) => {
        let {
            currentBetting: {postGroup}, tempItem, isLockBet
        } = this.state;
        if (isLockBet) {
            PNotify.info({
                title: this.props.t("messages:error"),
                text: this.props.t("messages:sale.error.results_are_coming_out"),
            });
            return false;
        }
        let postGroupItem = postGroup[tempItem.sectionNumber - 1];
        if (postGroupItem) {
            postGroupItem.items[tempItem.index] = betItem;
            postGroupItem.summaryAmount.betAmount -= tempItem.totalAmount;
            postGroupItem.summaryAmount.betAmount += betItem.totalAmount;
            this.setState({
                currentBetting: {...this.state.currentBetting}
            });
        }
    };
    getSelectedPost = () =>
        this.state.posts.filter((p) => p.checked).map((p) => p.id);


    /* validate edit post */
    handleSubmitPost = () => {

        let {postItemsTemp, currentBetting: {postGroup, totalUsd, totalKhr}} = this.state;
        const posts = this.getSelectedPost();

        if (posts.length === 0) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.post_required"),
            });
            return false;
        }


        let postGroupItem = postGroup[postItemsTemp.index];
        postGroupItem.posts = posts.join("");
        let grandTotalKhr = 0;
        let grandTotalUsd = 0;


        postGroupItem.items.map((item) => {
            item.totalAmount = posts.length * item.betAmount * item.numberDetail.split(",").length;
            if (item.currencyCode === "KHR") grandTotalKhr += item.totalAmount;
            else grandTotalUsd += item.totalAmount;
            return item;
        });

        totalKhr -= postGroupItem.summaryAmount.totalKhr.betAmount;
        totalKhr += grandTotalKhr;
        totalUsd -= postGroupItem.summaryAmount.totalUsd.betAmount;
        totalUsd += grandTotalUsd;
        postGroupItem.summaryAmount.totalKhr.betAmount = grandTotalKhr;
        postGroupItem.summaryAmount.totalUsd.betAmount = grandTotalUsd;
        this.setState({
            currentBetting: {
                postGroup: postGroup,
                totalUsd, totalKhr
            }
        });
        this.onClearAll(true);
    };

    /* handle on double click bet item for edit*/
    onDoubleClick = (e, index) => {
        e.index = index;
        this.handlePostCheckChange("escape");
        this.setState({postItemsTemp: null}, () => this.onEditOrDelete(e));
    };

    /* handle on Edit bet item*/

    onEditItem = (e, index) => {
        e.index = index;
        this.handlePostCheckChange("escape");
        this.setState({postItemsTemp: null}, () => this.onEditOrDelete(e));
    };

    /* handle double click post for edit post */
    onDoubleClickPost = (item) => {
        this.handlePostCheckChange("escape");
        this.setState({tempItem: null}, () => this.onEditPost(item));
    };

    /* handle edit bet item */
    onEditOrDelete = (e) => {
        const setVal = (state) => this.setState(state);
        this.handleBetTypeCheckChange(
            e.betType === "MULTIPLE" ? "NORMAL" : e.betType
        );
        this.handlePostCheckChange("escape");
        let isLo = e.posts.includes("Lo");
        this.handlePostCheckChange(this.getPostEdited(e.posts));
        if (isLo) this.handlePostCheckChange("Lo");

        this.handleCurrencyCheckChange(e.currencyCode);
        if (e.numberThree) this.handleSpecBetCheckCheckChange("3D", true);

        setVal({selectedMulti: e.multiDigit > 0});

        this.setState((preState) => ({
            ...preState,
            tempItem: e,
            pairStatus: e.pairStatus,
            fields: {
                betAmount: e?.betAmount ?? 0,
                multiDigit: e?.multiDigit ?? "",
                numberFrom: e?.numberFrom ?? "",
                numberTo: e?.numberTo ?? "",
            },
        }));
        this.firstDigitInput.current.focus();
    };

    /* handle delete bet item */
    onDeleteItem(tempItem) {
        let {
            currentBetting: {postGroup},
        } = this.state;
        if (tempItem) {
            let postGroupItem = postGroup[tempItem.sectionNumber - 1];
            if (postGroupItem.items.length === 1) {
                postGroup.splice(tempItem.sectionNumber - 1, 1);
            } else {
                postGroupItem.items.splice(tempItem.index, 1);

            }
            postGroupItem.summaryAmount.betAmount -= tempItem.totalAmount;
            this.setState(
                {
                    currentBetting: {
                        postGroup: postGroup
                    }
                }
            );
            this.onClearAll();
        }

    }

    /* handle on bet input change value  */
    onInputChange = (event) => {
        const {name, value} = event.target;
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    /* get range number from number1 to number2 */
    getRangeNumbers = () => {
        const data = this.state;
        const {fields} = data;
        if (parseInt(fields.numberFrom) > parseInt(fields.numberTo)) {
            return [];
        }
        return getNormalRange(fields.numberFrom, fields.numberTo);
    };

    checkString = (string1, string2) => {
        let strs = string1.split("");
        let str2s = string2.split("");
        let valid = false;
        for (let i = 0; i < strs.length; i++) {
            if (strs[i] === str2s[i]) {
                valid = true;
            }
        }
        return valid;
    }

    checkStringPair = (str, lg = 1) => {
        return [...new Set(str.split(""))].length === lg;
    }


    /* validate enter bet */
    validateBetting = () => {
        const {
            fields,
            currency,
            betTypes: {activeBetTypeId}
        } = this.state;

        PNotify.removeAll();
        const fromLength = fields.numberFrom.length;
        const selectedPost = this.getSelectedPost();

        if (selectedPost.length === 0) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.post_required"),
            });
            return false;
        }
        if (fields.betAmount <= 0) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.bet_amount_required"),
            });
            return false;
        }
        if (currency === "KHR" && fields.betAmount < 100) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.bet_amount_min_required"),
            });
            return false;
        }
        if (
            currency === "KHR" &&
            parseFloat(fields.betAmount) % 100 !== 0
        ) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.bet_amount_in_valid"),
            });
            return false;
        }

        if (fromLength < 2) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t(
                    "messages:sale.error.number_bet_must_be_greater_than"
                ),
            });
            return false;
        }
        if (fromLength > 3) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.bet_number_invalid"),
            });
            return false;
        }
        const from = fields.numberFrom;
        const to = fields.numberTo;

        let isPair = this.checkStringPair(from) && this.checkStringPair(to);

        if (activeBetTypeId === "SPECIAL_RANGE") {
            if (!isPair && !this.checkString(from, to)) {
                PNotify.info({
                    title: this.props.t("messages:sale.error.title"),
                    text: this.props.t("messages:sale.error.special_range_invalid"),
                });
                return false;
            }
        }

        switch (activeBetTypeId) {
            case "RANGE":
            case "SPECIAL_RANGE":

                if (from.length !== to.length) {
                    PNotify.info({
                        title: this.props.t("messages:sale.error.title"),
                        text: this.props.t("messages:sale.error.bet_number_must_be_the_same_length"),
                    });
                    return false;
                }

                if (parseInt(from) - parseInt(to) === 0) {
                    PNotify.info({
                        title: this.props.t("messages:sale.error.title"),
                        text: this.props.t("messages:sale.error.bet_number_cannot_the_same"),
                    });
                    return false;
                }
                if (parseInt(from) - parseInt(to) > 0) {
                    PNotify.info({
                        title: this.props.t("messages:sale.error.title"),
                        text: this.props.t("messages:sale.error.bet_from_number_must_be_less_than"),
                    });
                    return false;
                }
                break;
            default:
        }
        return true;
    };

    /* get change on digit for SPECIAL_RANGE */
    setIsDigit = (type) => {
        const {fields} = this.state;
        if (type === "SPECIAL_RANGE") {
            let diffIndex = [];
            const from = fields.numberFrom.split("");
            const to = fields.numberTo.split("");
            from.forEach((item, i) => {
                diffIndex.push(item !== to[i]);
            });
            if (diffIndex.length > 0) {
                return diffIndex;
            }
            return [false, false, false, false];
        } else {
            return [false, false, false, false];
        }
    };

    /* handle bet item by enter */
    handleSubmitEnter = (isValidate = true, isSubmit = false, autoPrint = false) => {
        const {
            filters: {currentTicket}, t
        } = this.props;
        if (!this.validateBetting()) {
            return null;
        }
        const data = this.state;
        let {
            isLockBet,
            fields,
            tempItem,
            pairStatus,
            selectedMulti,
            currentBetting: {postGroup},
        } = data;

        let betItems = postGroup.map(e => {
            return e.items;
        });
        const merged = [].concat.apply([], betItems);
        if (merged.length > 49) {
            PNotify.info({
                title: this.props.t("messages:error"),
                text: this.props.t("messages:sale.error.error_limit_amount"),
            });
            return false;
        }

        const posts = this.getSelectedPost();
        let betType = data.betTypes.activeBetTypeId;
        let betTitle = betType;
        let numbers = [];
        let numberConfirm = [];
        let titles = [];
        const {numberFrom, numberTo} = fields;
        let fromLength = numberFrom.length;
        const isDigit = this.setIsDigit(betType);

        switch (betType) {
            case "RANGE":
                numbers = this.getRangeNumbers();
                break;
            case "SPECIAL_RANGE":
                if (
                    selectedMulti &&
                    this.checkStringPair(numberFrom) &&
                    this.checkStringPair(numberTo) && fromLength > 2
                )
                    numbers = specialTripleX(numberFrom, numberTo);
                else numbers = getSpecialRange(numberFrom, numberTo);

                if (numbers.length === 0) {
                    PNotify.info({
                        title: this.props.t("messages:sale.error.title"),
                        text: this.props.t("messages:sale.error.special_range_invalid"),
                    });
                    return false;
                }
                isDigit.forEach((digit, index) => {
                    if (digit) {
                        titles.push("J" + (index + 1));
                    }
                });
                if (titles.length > 0) {
                    betTitle += "_" + titles.join("_");
                }
                break;
            default:
                // NORMAL
                numbers = [numberFrom];
                break;
        }
        if (pairStatus === 1) numbers = getPair(numbers);
        if (pairStatus === 2) numbers = getNotPair(numbers);
        if (selectedMulti) {
            numberConfirm = numbers;
            if (!(this.checkStringPair(numberFrom) &&
                this.checkStringPair(numberTo) && fromLength > 2)) {
                fields.multiDigit = numberFrom.length;
                if (numbers.length > 0) {
                    numberConfirm = [];
                    numbers.forEach((number) => {
                        const multiNumber = getMulti(number, fields.multiDigit);
                        numberConfirm.push(...multiNumber);
                    });
                    numberConfirm = [...new Set(numberConfirm)];
                }
            } else fields.multiDigit = numberFrom.length;

            let types = ["RANGE", "SPECIAL_RANGE"];
            if (types.includes(betType))
                betTitle += "_MULTIPLE";
            else {
                betTitle = "MULTIPLE";
                betType = "MULTIPLE";
            }
            if (betType === "SPECIAL_RANGE" && titles.length > 1 && numberFrom.length === 2) {
                if (numberFrom.length === titles.length) {
                    betTitle = betTitle.replace("_MULTIPLE", "");
                    fields.numberMulti = null;
                }
            }

        } else
            numberConfirm = numbers;
        let betAmount = parseFloat(fields.betAmount);
        let totalAmount = numberConfirm.length * this.getSelectedPost().length * betAmount;
        let postString = posts.join("");
        let sectionNumber = currentTicket;
        if (_.isEmpty(numberConfirm)) {
            PNotify.info({
                title: this.props.t("messages:sale.error.title"),
                text: this.props.t("messages:sale.error.bet_number_invalid"),
            });
            return false;
        }

        if (numberConfirm.length > 100) {
            PNotify.info({
                title: this.props.t("messages:error"),
                text: this.props.t("messages:sale.error.error_limit_number"),
            });
            return false;
        } else {
            if (!selectedMulti) fields.multiDigit = null
            else fields.multiDigit = fields.numberFrom.length
        }
        if (numberConfirm.length > 10 && isValidate) {
            this.setState({isFocus: false});
            swal({
                title: this.props.t("messages:confirm.are_you_sure"),
                text: this.props.t("messages:confirm.continue_betting"),
                icon: "warning",
                buttons: [t("buttons:no"), t("buttons:yes")],
                successMode: true,
            }).then((value) => {
                if (value) {
                    this.handleSubmitEnter(false)
                } else return false;
            });
            return false;
        }
        const betRQ = new BetRQ({
            posts: posts.join(""),
            betType: betType,
            betTitle: betTitle,
            sectionNumber: sectionNumber,
            currencyCode: data.currency,
            multiDigit: fields.multiDigit,
            numberFrom: fields.numberFrom,
            numberTo: fields.numberTo ?? "",
            betAmount: betAmount,
            numberDetail: numberConfirm.join(","),
            numberQuantity: numberConfirm.length,
            totalAmount: totalAmount,
            pairStatus: pairStatus,
        });

        if (tempItem) {
            betRQ.sectionNumber = tempItem.sectionNumber;
            this.onSubmitEditItem(betRQ);
            this.onClearAll();
            return false;
        } else if (isLockBet) {
            PNotify.info({
                title: this.props.t("messages:error"),
                text: this.props.t("messages:sale.error.results_are_coming_out"),
            });
            return false;
        }

        let selectedPost =
            currentTicket > postGroup.length ? "" : this.findGroupPost(postString, currentTicket);
        if (selectedPost !== "") {
            selectedPost.items.push(betRQ);
            selectedPost.summaryAmount.betAmount += totalAmount;
        } else {
            betRQ.sectionNumber = postGroup.length > 0 ? postGroup.length + 1 : 1;
            postGroup.push({
                posts: postString,
                summaryAmount: {
                    betAmount: totalAmount,
                },
                items: [betRQ],
            });
        }

        if (isSubmit) this.handleSubmitBetting(autoPrint, postGroup)
        else this.setState({
            currentBetting: {
                ...this.state.currentBetting,
                postGroup: postGroup,
            },
            fields: {
                ...fields,
                numberFrom: "",
                multiDigit: null,
            },
        });
        this.firstDigitInput.current.focus();
        let elem = document.getElementById('betting-item');
        if (elem) elem.scrollTop = elem.scrollHeight;
        this.props.handleSelectChange("currentTicket", postGroup.length > 0 ? postGroup.length : 1);

    };

    /* delete all betting items */
    onClearBetItem = () => {
        const {t} = this.props;
        swal({
            title: t("messages:confirm.are_you_sure"),
            text: t("messages:confirm.clear_current_bet"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value)
                this.setState({
                    currentBetting: {
                        postGroup: [],
                        totalUsd: 0,
                        totalKhr: 0
                    },
                });
        });
    };

    onClearItem = () => {
        const {t} = this.props;
        swal({
            title: t("messages:confirm.are_you_sure"),
            text: t("messages:confirm.clear_current_bet"),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((value) => {
            if (value)
                this.setState({
                    currentBetting: {
                        postGroup: [],
                    },
                });
        });
    };

    /* handle submit betting */

    handleSubmitBetting = (autoPrint, pg = []) => {

        let {currentBetting: {postGroup}, isBetting} = this.state;

        if (postGroup.length === 0) {
            this.handleSubmitEnter(true, true, autoPrint);
            return false;
        }

        if (isBetting) return null;
        const {
            postBetting,
            drawing,
            recentTicket: {filter}
        } = this.props;

        if (postGroup.length === 0)
            postGroup = pg;
        if (postGroup.length > 0 && drawing.currentDraw.drawCode) {
            let betting = [];
            postGroup.forEach((item) => {
                betting.push(...item.items);
            });
            this.setState({isBetting: true});
            postBetting({
                drawCode: drawing?.currentDraw.drawCode,
                items: betting,
            }).then(async (lane) => {
                this.setState({
                    currentBetting: {
                        postGroup: [],
                        totalUsd: 0,
                        totalKhr: 0
                    },
                });
                this.setState(pre => ({
                    ...pre,
                    fields: {
                        ...pre.fields,
                        betAmount: 0
                    }
                }))
                this.setState({isBetting: false});
                this.props.fetchUserBalance();
                this.props.fetchLotto5dRecentTicket({...filter});
                PNotify.removeAll();
                PNotify.success({
                    title: this.props.t("messages:success"),
                    text: this.props.t("messages:sale.success"),
                });
                autoPrint && this.printTicket(lane);
                this.props.handleSelectChange("currentTicket", 1);
                this.onClearAll();
            }).catch(error => {
                console.log("error=======>");
                this.setState({isBetting: false});
                error && showErrorMessage(error, this.props.t);
                this.props.setLoading(ERROR_LOADING)
            });
        }
    };

    printTicket = (lane) => {
        this.setState({ticket: lane.ticket}, () => {
            this.state.ticket && document.getElementById("print_btn").click();
        });
    };

    renderTicket = () => {

        const {ticket} = this.state;
        return <div style={{display: 'none'}}>
            <div>
                <Ticket58 lotteryType="LO5D" ref={el => (this.componentRef = el)}
                          object={ticket} {...this.props}/>
            </div>
            <ReactToPrint
                copyStyles={true}
                onAfterPrint={() => {
                    this.setState({ticket: null});
                    this.props.fetchCurrentDraw5D();
                    this.firstDigitInput.current.focus();
                }}
                trigger={() => {
                    return <button id="print_btn" className='bg-transparent border-0'>
                    </button>;
                }}
                content={() => this.componentRef}
            />
        </div>
    };


    /* Searching for same post group for update bet item*/
    findGroupPost = (posts, currentTicket) => {
        const {
            currentBetting: {postGroup}
        } = this.state;

        if (currentTicket < postGroup.length) {
            if (postGroup.length > 0) {
                const lastPost = postGroup[currentTicket - 1];
                return lastPost.posts === posts ? lastPost : "";
            }
        } else {
            if (postGroup.length > 0) {
                const lastPost = postGroup[postGroup.length - 1];
                return lastPost.posts === posts ? lastPost : "";
            }
        }
        return "";
    };

    /* handle remove last bet item form betting board */
    deleteLastBet = () => {
        let {
            currentBetting: {postGroup, totalKhr, totalUsd, ...currentBetting},
        } = this.state;
        if (postGroup.length > 0) {
            const lastGroup = postGroup[postGroup.length - 1];
            if (lastGroup.items.length === 1) {
                postGroup.pop();
                const item = lastGroup.items[0];
                this.setState(
                    {
                        currentBetting: {
                            ...currentBetting,
                            postGroup: postGroup,
                        },
                    },
                    () => this.props.handleSelectChange("currentTicket", postGroup.length > 0 ? postGroup.length : 1)
                );
                if (item.currencyCode === "KHR")
                    totalKhr -= item.totalAmount;
                else totalUsd -= item.totalAmount;
                this.setState({
                    currentBetting: {...this.state.currentBetting, totalUsd, totalKhr}
                });
                return null;
            }
            if (lastGroup.items.length > 1) {
                const remove = lastGroup.items.pop();
                if (remove.currencyCode === "KHR") {
                    lastGroup.summaryAmount.totalKhr.betAmount -= remove.totalAmount;
                } else {
                    lastGroup.summaryAmount.totalUsd.betAmount -= remove.totalAmount;
                }
                postGroup[postGroup.length - 1] = lastGroup;
                if (remove.currencyCode === "KHR")
                    totalKhr -= remove.totalAmount;
                else totalUsd -= remove.totalAmount;

                this.setState(
                    {
                        currentBetting: {
                            postGroup: postGroup,
                            totalUsd,
                            totalKhr
                        },
                    },
                    () => this.props.handleSelectChange("currentTicket", postGroup.length > 0 ? postGroup.length : 1));
            } else {
                postGroup.pop();
                this.setState({
                    currentBetting: {
                        ...currentBetting,
                        postGroup: postGroup,
                    },
                });
            }

            this.setState({
                currentBetting: {...this.state.currentBetting, totalUsd, totalKhr}
            });
        }
    };


    /* handle on changed bet type */
    handleBetTypeCheckChange(betType) {
        const betTypes = this.state.betTypes;
        betTypes.activeBetTypeId = betType;
        this.setState({
            betTypes,
            focusIndex: 0,
        });
        if (this.state.isFocus) this.firstDigitInput.current && this.firstDigitInput.current.focus();
        if (betType === "MATRIX") {
            this.setState({specBetCheck: null});
        }
    }

    /* handle multiple check */
    handleMultipleCheckChange = () => {
        this.setState(pre => ({
            ...pre,
            selectedMulti: !pre.selectedMulti
        }));
    };

    /* handle change post */
    handlePostCheckChange(name, isClear = false) {
        if (this.state.tempItem) return false;
        let posts = this.state.posts.map((p) => {
            if (name === "all") {
                return Object.assign({}, p, {
                    checked: this.getSelectedPost().length < 4 ? true : !p.checked
                });
            } else if (p.id === name || name.indexOf(p.id) !== -1) {
                return Object.assign({}, p, {
                    checked: isClear ? true : !p.checked,
                });
            } else if (name === "escape" || isClear)
                return Object.assign({}, p, {
                    checked: false,
                });
            return p;
        });

        this.setState({posts: posts});
    }

    /* handle change currency KHR/USD */
    handleCurrencyCheckChange(value) {
        this.setState({
            currency: value,
        });
    }

    /* handle change checkbox special bet */
    handleSpecBetCheckCheckChange(value, isClear = false) {
        this.setState({
            specBetCheck:
                value === this.state.specBetCheck && !isClear ? null : value,
        });
    }


    /* clear betting board */
    onClearAll = (isEdit = false) => {
        this.handlePostCheckChange("escape");
        this.handleBetTypeCheckChange("NORMAL", isEdit);
        this.setState((prevState) => ({
            ...prevState,
            focusIndex: 0,
            selectedMulti: false,
            tempItem: null,
            postItemsTemp: null,
            fields: {
                ...prevState.fields,
                multiDigit: null,
                numberFrom: "",
                numberTo: ""
            },
        }));
        const nums = this.state.currentBetting.postGroup.length;
        this.props.handleSelectChange("currentTicket", nums > 0 ? nums : 1);
        return null;
    };

    resetBet = () => {
        this.onClearAll();
        this.setState(pre => ({
            ...pre,
            fields: {
                ...pre.fields,
                betAmount: 0
            }
        }));
    };

    /* handle key shortcut */
    handleKeyDown(e) {
        const filters = this.props.filters;
        const graveKey = this.state.graveKey;
        this.setState({graveKey: e.keyCode === KEY_CODE.grave_accent});

        /* increase currentTicket */
        if (e.keyCode === KEY_CODE.right_arrow) {
            e.preventDefault();
            this.props.handleSelectChange("currentTicket", filters.currentTicket + 1);
            return null;
        }

        /* clear current betting */
        if (
            e.keyCode === KEY_CODE.escape ||
            (e.shiftKey && e.keyCode === KEY_CODE.delete)
        ) {
            e.preventDefault();
            this.resetBet()

        }
        const {isFocus} = this.state;
        const {isEdit} = this.props;

        /* focus & blur input */
        if (e.keyCode === KEY_CODE.space) {
            e.preventDefault();
            if (!isFocus) {
                this.focusInputAmount();
            } else this.unFocusInputAmount();
            this.setState((prevState) => ({
                ...prevState,
                isFocus: !isFocus,
            }));
            return null;
        }
        if (isFocus && !isEdit) {
            switch (true) {
                /* submit current betting */
                case e.keyCode === KEY_CODE.left_arrow: {
                    e.preventDefault();
                    this.handleSubmitBetting(this.state.autoPrint);
                    break;
                }
                /* enter betting item */
                case e.keyCode === KEY_CODE.enter: {
                    e.preventDefault();
                    this.handleSubmitEnter();
                    break;
                }

                // /*change currency*/
                // case e.keyCode === KEY_CODE.subtract: {
                //     e.preventDefault();
                //     this.handleCurrencyCheckChange(
                //         this.state.currency === "USD" ? "KHR" : "USD"
                //     );
                //     break;
                // }

                /*key delete to remove last bet*/
                case e.keyCode === KEY_CODE.delete:
                    e.preventDefault();
                    this.deleteLastBet();
                    break;

                /*multi post select*/
                case e.shiftKey &&
                (e.keyCode === KEY_CODE["1"] || e.keyCode === KEY_CODE.f1 || e.keyCode === KEY_CODE.numpad1): {
                    e.preventDefault();
                    this.handlePostCheckChange("A");
                    break;
                }

                case (graveKey || e.shiftKey) &&
                (e.keyCode === KEY_CODE["2"] || e.keyCode === KEY_CODE.f2 || e.keyCode === KEY_CODE.numpad2): {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B"]);
                    break;
                }

                case (graveKey || e.shiftKey) &&
                (e.keyCode === KEY_CODE["3"] || e.keyCode === KEY_CODE.f3 || e.keyCode === KEY_CODE.numpad3): {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B", "C"]);
                    break;
                }

                case (graveKey || e.shiftKey) &&
                (e.keyCode === KEY_CODE["4"] || e.keyCode === KEY_CODE.f4 || e.keyCode === KEY_CODE.numpad4): {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B", "C", "D"]);
                    break;
                }



                /*all post*/
                case (graveKey || e.shiftKey) && (e.keyCode === KEY_CODE["9"] || e.keyCode === KEY_CODE.numpad9): {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B", "C", "D"]);
                    break;
                }

                case e.keyCode === KEY_CODE.insert: {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B"]);
                    break;
                }

                case e.keyCode === KEY_CODE.end: {
                    e.preventDefault();
                    this.handlePostCheckChange(["B", "C", "D"]);
                    break;
                }

                case e.keyCode === KEY_CODE.page_down: {
                    e.preventDefault();
                    this.handlePostCheckChange(["A", "B", "C", "D"]);
                    break;
                }


                case e.keyCode === KEY_CODE.f11: {
                    e.preventDefault();
                    this.handlePostCheckChange("all");
                    break;
                }

                /*single post select*/
                case e.keyCode === KEY_CODE.f1: {
                    e.preventDefault();
                    this.handlePostCheckChange("A");
                    break;
                }

                case e.keyCode === KEY_CODE.f2: {
                    e.preventDefault();
                    this.handlePostCheckChange("B");
                    break;
                }

                case e.keyCode === KEY_CODE.f3: {
                    e.preventDefault();
                    this.handlePostCheckChange("C");
                    break;
                }

                case e.keyCode === KEY_CODE.f4: {
                    e.preventDefault();
                    this.handlePostCheckChange("D");
                    break;
                }


                /*change betType to next*/
                case e.keyCode === KEY_CODE.divide: {
                    e.preventDefault();
                    let betTypeIndex = this.state.betTypes.betType.findIndex(
                        (b) => b.id === this.state.betTypes.activeBetTypeId
                    );
                    if (betTypeIndex === this.state.betTypes.betType.length - 1)
                        betTypeIndex = 0;
                    else betTypeIndex++;
                    this.handleBetTypeCheckChange(
                        this.state.betTypes.betType[betTypeIndex].id
                    );
                    break;
                }

                /*change betType to next*/
                case e.keyCode === KEY_CODE.multiply: {
                    e.preventDefault();
                    this.handleMultipleCheckChange();
                    break;
                }

                /*change betType by Char*/
                case e.keyCode === KEY_CODE.a: {
                    e.preventDefault();
                    this.handleBetTypeCheckChange("NORMAL");
                    break;
                }
                case e.keyCode === KEY_CODE.s: {
                    e.preventDefault();
                    this.handleBetTypeCheckChange("RANGE");
                    break;
                }
                case e.keyCode === KEY_CODE.d: {
                    e.preventDefault();
                    this.handleBetTypeCheckChange("SPECIAL_RANGE");
                    break;
                }

//                 case e.keyCode === KEY_CODE.g: {
//                     e.preventDefault();
//                     this.setState((preState) => ({
//                         ...preState,
//                         pairStatus: preState.pairStatus === 2 ? 0 : 2
//                     }));
//                     break;
//                 }

                /*multiply*/
                case e.keyCode === KEY_CODE.x: {
                    e.preventDefault();
                    this.handleMultipleCheckChange();
                    break;
                }

                default: {
                    return;
                }
            }
        }
        this.setState({graveKey: e.keyCode === KEY_CODE.grave_accent});
    }

    /* handle key press on bet input */
    handleDigitKeyPress(e) {
        if (e.keyCode === KEY_CODE.add || e.keyCode === KEY_CODE.tab) {
            e.preventDefault();
            const length = 2;
            const form = e.target.form;
            let els = Array.from(form.elements).filter(e => e.type === "text");
            const index =
                Array.prototype.indexOf.call(form, e.target) === length
                    ? -1
                    : Array.prototype.indexOf.call(form, e.target);
            if (els[index + 1]) els[index + 1].focus();
            else els[0].focus();
        }
    }

    /* render bet input */
    renderInputAmountCol = () => {
        const {fields, betTypes: {activeBetTypeId}} = this.state;
        const {t, config: {lotto90PackagePrices}} = this.props;
        const isMatrix = activeBetTypeId === "MATRIX";
        return (
            <RowCard nonMb bodyClass="p-2">
                <Row className="mr-2 justify-content-end">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row className="m-0 align-items-end">
                            <div style={{paddingRight: ".8vw"}} className="col">
                                <div className="align-items-center d-flex ">
                                    <div className="pr-0 m-0">
                                        <InputNumber
                                            onKeyDown={this.handleDigitKeyPress}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            width="6vw"
                                            readOnly={true}
                                            className="form-control"
                                            maxLength="3"
                                            isMatrix={isMatrix}
                                            name="numberFrom"
                                            t={t}
                                            onFocus={() =>
                                                this.setState({isFocus: true, focusIndex: 0})
                                            }
                                            onSelect={() => this.setState({isSelectFrom: true})}
                                            value={fields.numberFrom ?? ""}
                                            onChange={this.onInputChange}
                                            myRef={this.firstDigitInput}
                                        />
                                    </div>
                                    {activeBetTypeId !== "NORMAL" && (
                                        <div style={{paddingLeft: ".3vw"}} className="pr-0">
                                            <InputNumber
                                                onKeyDown={this.handleDigitKeyPress}
                                                onMouseDown={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                maxLength="3"
                                                width="6vw"
                                                t={t}
                                                isActive={this.state.betTypes.activeBetTypeId !== "NORMAL"}
                                                readOnly={true}
                                                className="form-control"
                                                name="numberTo"
                                                isMatrix={isMatrix}
                                                onFocus={() =>
                                                    this.setState({isFocus: true, focusIndex: 1})
                                                }
                                                onSelect={() => this.setState({isSelectTo: true})}
                                                disabled={this.state.betTypes.activeBetTypeId === "NORMAL"}
                                                value={fields.numberTo ?? ""}
                                                onChange={this.onInputChange}
                                                myRef={this.twoDigitInput}
                                                id="numberTo"
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className="ml-0 mr-0">
                                <Label className="m-0">ទឹកប្រាក់
                                    <button type="button" onClick={() => {
                                        this.setState(pre => ({
                                            ...pre,
                                            fields: {
                                                ...pre.fields,
                                                betAmount: 0
                                            }
                                        }))
                                    }} className="bg-transparent border-0 mb-0">
                                        <i
                                            className="feather icon-refresh-ccw ml-3 f-16 text-warning"/>
                                    </button>
                                </Label>
                                <InputNumber
                                    className="form-control"
                                    onKeyDown={this.handleDigitKeyPress}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    maxLength="10"
                                    width="6.5vw"
                                    value={fields.betAmount === 0 ? "" : fields.betAmount}
                                    readOnly={true}
                                    isKhr={this.state.currency === "KHR"}
                                    name="betAmount"
                                    onFocus={() =>
                                        this.setState({isFocus: true, focusIndex: 4})
                                    }
                                    onSelect={() => this.setState({isBetAmount: true})}
                                    myRef={this.amountDigitInput}
                                    onChange={this.onInputChange}
                                />
                            </div>
                        </Row>
                    </Form>
                    {/*<div className="ml-2">*/}
                    {/*    <RenderCurrency currency={currency} handleCurrencyCheckChange={this.handleCurrencyCheckChange}*/}
                    {/*                    t={t}/>*/}
                    {/*</div>*/}
                </Row>
                <div className="d-flex pt-2 justify-content-end">
                    {Array(10).fill(0).map((e, i) => {
                        return <button onClick={() => this.onClickBetNumber(i)} key={i} className="number-btn">
                            {i}
                        </button>
                    })}
                </div>
                <div className="d-flex flex-wrap justify-content-end mr-2 mt-3 lo5d-button">
                    {
                        lotto90PackagePrices.map((p, i) => {
                            return <button
                                key={i}
                                onClick={() => this.onClickAmount(p)}
                                className={`mb-1 post price`}>{p.label}</button>
                        })
                    }
                </div>
            </RowCard>
        );
    };

    /* render UI betting board */
    render() {
        const {
            currentBetting, tempItem, postItemsTemp,
            ticket, betTypes: {
                betType, activeBetTypeId
            },
            selectedMulti,
            posts,
        } = this.state;
        const {user: {userBalance}, audio} = this.props;

        const playSound = (src) => {
            if (audio && !audio.isMute) playAudio(src);
        }

        return (
            <div className="betting-board lo5d mt-1 lo5d-v1">
                <button id="refresh-draw" className="d-none" onClick={() => this.props.fetchCurrentDraw5D()}/>
                {ticket && this.renderTicket()}
                <div className="m-0">
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                            <div className="lo90-v1-l-side border">
                                <Balance pendingAmount={userBalance.pendingLotto5d}/>
                                <RecentTicket/>
                            </div>
                            <div className="lo90-v1-mid-side">
                                <DrawingResult playSound={playSound} {...this.props}/>
                            </div>
                            <div className="lo90-v1-r-side border">
                                <div className="w-betting-board">
                                    <div className="position-relative pl-pr pr-1">
                                        <div className="row m-0">
                                            <div className="col pl-0 lo5d pr-0">
                                                <div className="form-betting w-right bg-grey pr-1 pl-1">
                                                    <div>
                                                        <RowCard>
                                                            {posts.map((p, i) => {
                                                                return <button key={i}
                                                                               onClick={() => this.handlePostCheckChange(p.id)}
                                                                               className={`post ${p.checked ? "post-active" : ""}`}>{p.id}</button>
                                                            })}
                                                            <button key="all"
                                                                    onClick={() => this.handlePostCheckChange("all")}
                                                                    className={`post ${this.getSelectedPost().length === 4 ? "post-active" : ""}`}>ALL
                                                            </button>
                                                        </RowCard>
                                                        {!postItemsTemp && <RowCard bodyClass="card-body">
                                                            <div>
                                                                {betType.map((p, i) => {
                                                                    return <button
                                                                        key={i}
                                                                        onClick={() => this.handleBetTypeCheckChange(p.id)}
                                                                        className={`post ${activeBetTypeId === p.id ? "post-active" : ""}`}>{p.title}</button>
                                                                })}
                                                                <button
                                                                    onClick={this.handleMultipleCheckChange}
                                                                    className={`post ${selectedMulti ? "post-active" : ""}`}>គុណ
                                                                </button>
                                                            </div>
                                                        </RowCard>}
                                                        {!postItemsTemp && this.renderInputAmountCol()}
                                                        {tempItem && <RowCard>
                                                            <Button
                                                                id="delete"
                                                                onClick={this.onClearAll}
                                                                className="bg-secondary border-0">
                                                                Cancel
                                                            </Button>
                                                            <Button id="save" onClick={this.handleSubmitEnter}
                                                                    className='m-1'>
                                                                Save
                                                            </Button>
                                                            <UncontrolledTooltip placement="bottom" target="save">
                                                                Enter
                                                            </UncontrolledTooltip>
                                                        </RowCard>}
                                                        {postItemsTemp && <RowCard>
                                                            <Button
                                                                id="delete"
                                                                onClick={this.onClearAll}
                                                                className="bg-secondary border-0">
                                                                Cancel
                                                            </Button>
                                                            <Button id="save" onClick={this.handleSubmitPost}
                                                                    className='m-1'>
                                                                Save
                                                            </Button>
                                                            <UncontrolledTooltip placement="bottom" target="save">
                                                                Enter
                                                            </UncontrolledTooltip>
                                                        </RowCard>}
                                                        <RenderBettingItem filters={this.props.filters}
                                                                           {...this.props}
                                                                           activeSubmit={true}
                                                                           handleSelectChange={this.props.handleSelectChange}
                                                                           onAdded={this.handleSubmitEnter}
                                                                           handleSubmitBetting={(e) => this.handleSubmitBetting(e)}
                                                                           onClearBetItem={this.resetBet}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.currentBetting.postGroup.length > 0 &&
                                                <div id="betting-item"
                                                     className="col bg-tran-white sc-betting position-absolute betting-item-card-white p-0">
                                                    <div className="p-1 bg-white rounded text-right border">
                                                        <button onClick={this.onClearBetItem}
                                                                className="border-0 bg-transparent mt-2">
                                                            <i className="feather icon-trash-2 f-25 text-danger"/>
                                                        </button>
                                                        <BetCard
                                                            classes="w-betting-card text-left"
                                                            onEditItem={this.onEditItem}
                                                            onClearBetItem={this.onClearBetItem}
                                                            onDeleteItem={this.onDeleteItem}
                                                            onEditPost={() => null}
                                                            {...currentBetting}
                                                            isBetting={true}
                                                            t={this.props.t}
                                                        />

                                                    </div>

                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <HistoryResult {...this.props}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}

export default withTranslation()(SaleV2);
