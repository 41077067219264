import {gql} from "@apollo/client";

export const getFirstLabel = (code) => {
    switch (code) {
        case "under":
            return null;
        case "over":
            return null;
        case "one":
        case "ONE":
            return "1";
        case "two":
        case "TWO":
            return "2";
        case "three":
        case "THREE":
            return "3";
        case "four":
        case "FOUR":
            return "4";
        case "five":
        case "FIVE":
            return "5";
        default:
            return null;
    }
}

export const SUBSCRIPTION_LOTTO5 = gql`
        subscription Lotto5Sub {
            v_lotto5_drawing {
                 drawCode
                 status
            }
           }`;


export const SUBSCRIPTION_CONFIG = gql`
       subscription subConfiguration {
            v_configurations {
                id
                is_active
                key
                value
                environment
    }
}`;