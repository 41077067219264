import ResultContentList from "./ResultContentList";
import DatetimeContainer from "../date/DatetimeContainer";
import CountDownTimer from "../timer/CountDownTimer";
import {getShortBetType} from "../../utils/tickets/printUtils";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    addLotto5Betting, clearCartLotto5,
    deleteLotto5Betting,
    fetchListLotto5Betting,
    fetchLotto5DrawingResult,
    fetchLotto5RecentDraw,
    fetchStatistic5,
    setBetStatusLotto5
} from "../../store/lotto5/action";
import {playAudio, setAudio} from "../../store/audio/action";
import {setRenderResultStatus, setResultTotalNumberKeno} from "../../store/lotto12/action";
import {fetchUserBalanceValidate, setIsAddToCart} from "../../store/user/action";
import {getDateHHmmss} from "../../utils/dateUtils";
import _ from "lodash";
import {env} from "../../env";
import betDownAudio from "../../assets/audios/betPlace.mp3";
import winSound from "../../assets/audios/lotto5_win.mp3";
import {
    clientAp,
    getOECode,
    isDisabledODEO,
    isOddEven,
    isOE,
    jackpotNum,
    pfType999,
    pfType999Color
} from "../../constants/general";
import {SUBSCRIPTION_LOTTO5} from "../../constants/lotto5";
import countdownAudio from "../../assets/audios/countdown.mp3";
import goodLuckAudio from "../../assets/audios/goodluck.mp3";
import stopDrawAudio from "../../assets/audios/drawStop.mp3";
import SweetAlert from "react-bootstrap-sweetalert";
import RecentBetDetailDialog from "./RecentDialog";
import BettingDialog from "./BettingDialog";
import swal from "sweetalert";
import {getResult} from "../../utils/general";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";
import LoadingGif from "../../utils/LoadingGif";

class DrawingResult extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            showBetting: false,
            showDeleteBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            totalResult: null,
            allNumbersDrawing: [],
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "hide-logo-render",
            classCountDown: "total-result-render-count",
            winUO: "",
            winRange: "",
            winOE: "",
            betMessage: "",
            isShowRecentBet: false,
            recentBetDetail: null,
            isShowValidateBalance: false,
            messageValidateBalance: null,
            status: null,
            isStoppedJackpot: true,
            showJackpotValue: false,
            isEnableBetNow: true,
            jackpot: 0,
        };
        this.onDrawStatusChange = this.onDrawStatusChange.bind(this);
    }


    onCloseValidateBalance() {
        this.setState({
            isShowValidateBalance: false,
            messageValidateBalance: null
        });
    }

    onBetting(item) {
        const {isStopBet, fetchUserBalanceValidate, t} = this.props;
        const {name, value, resultRange, code} = item;
        if (!isStopBet) {
            fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: name,
                        betGameOdds: value,
                        betGameRange: resultRange,
                        betCode: code,
                        betGameTime: getDateHHmmss(new Date())
                    });
                }).catch(error => {
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error?.response?.data?.message)
                });
            })
        }
    }


    setWinLose = (indexResultItem) => {
        const {oldResult} = this.props;
        let jp = _.toUpper(oldResult?.rangeCode) === oldResult.jackpotType ? jackpotNum[oldResult.jackpotType] : 0
        this.jackpotInv && clearInterval(this.jackpotInv);
        jp > 0 && this.playAudio(winSound);

        this.setState({
            winUO: _.toUpper(oldResult?.underOverCode),
            winOE: _.toUpper(oldResult?.oddEvenCode),
            winRange: _.toUpper(oldResult?.rangeCode),
            resultItem: null,
            indexResultItem: indexResultItem,
            totalResult: null,
            jackpot: jp,
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
            classCountDown: "total-result-render-count"
        })
    }

    refreshData = () => {
        this.preSetNewGame();
        this.props.fetchLotto5RecentBetReportCache();
        this.props.fetchStatistic5();
        this.props.fetchUserBalance();
        this.getRecentDraw();
        this.setState({showJackpotValue: false});
    }

    preSetNewGame = () => {
        if (this.drawingTime) {
            clearInterval(this.drawingTime);
        }
        this.setState({
            winUO: "",
            winRange: "",
            winOE: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
            classCountDown: "total-result-render-count"
        });
        this.props.setRenderResultStatus({isStopRender: true});
        this.setNewGame();
    }

    setNewGame = () => {
        this.props.fetchLotto5DrawingResult()
            .then(() => {
                if (this.props.countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "hide-number-draw",
                        classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
                        classCountDown: "total-result-render-count"
                    })
                    this.countingSecond(this.props.countDownTimer)
                }
            })
    }

    checkDrawingResult = () => {
        this.props.fetchLotto5DrawingResult()
            .then(() => {
                const {countDownTimer} = this.props;
                this.setBettingStatusByTimer(countDownTimer)
                if (countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "hide-number-draw",
                        classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
                        classCountDown: "total-result-render-count"
                    })
                }
                if (countDownTimer > 10) {
                    this.playAudio(betDownAudio)
                } else if (countDownTimer <= 0) {
                    this.setState({
                        classNameNumberDraw: "show-number-draw",
                        classNameLogoRender: "hide-logo-render",
                        classCountDown: "total-result-render-count-total"
                    })
                    this.props.setRenderResultStatus({isStopRender: false});
                    return this.getReleaseNum();
                }
            }).catch(() => {
            this.setState({allNumbersDrawing: []})
        })
    }

    subscription = () => {
        if (socketLottoConfig.l5) {
            socket.on(socketEvent.l5, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO5,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto5_drawing) {
                        let currentDraw = event.data.v_lotto5_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    componentDidUpdate() {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    componentDidMount() {
        this.subscription();
        this.props.fetchUserBalance();
        this.props.fetchListLotto5Betting({isClear: true});
        this.checkDrawingResult();
        this.props.fetchLotto5RecentBetReport();
        this.props.fetchStatistic5();
        this.getRecentDraw();
    }

    getRecentDraw = () => {
        this.props.fetchLotto5RecentDraw()
            .then(() => {
                if (this.props.isStopRender && this.props.lastResult) {
                    const details = this.props.lastResult.details
                    this.setState({allNumbersDrawing: details})
                }
            })
    }

    componentWillUnmount() {
        this.drawingTime && clearTimeout(this.drawingTime);
        this.random && clearTimeout(this.random);
        this.startDrawing && clearTimeout(this.startDrawing)
        this.jackpotInv && clearInterval(this.jackpotInv);
        removeSocketListener(socketEvent.l5);
    }

    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0;
        this.setState({
            betAmount: parseInt(betAmount.toString()) + amount
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        const value = e.target.value;
        if (value >= 0 || !isNaN(value) || value === undefined) {
            this.setState({betAmount: value})
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {minBet, maxBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    submitBetNow = () => {
        const {isEnableBetNow} = this.state;
        isEnableBetNow && this.props.lotto5ConfirmBettingNow(() => {
            this.setState({isEnableBetNow: false})
        }).then(() => {
            this.setState({isEnableBetNow: true})
            this.props.fetchLotto5RecentBetReport().then(() => this.props.user.isAutoPrint && document.getElementById("print-button").click());
            this.props.fetchUserBalance();
        }).catch((error) => {
            const {response} = error;
            this.setState({isEnableBetNow: true})
            response && swal(response.data.message, {icon: "error", button: "បិទ"});
        })
    }


    onConfirmBettingLotto5 = async (logs) => {
        const {t, user: {userBalance, isAddToCart}, newResult, fetchListLotto5Betting} = this.props;
        let totalCartAmount = fetchListLotto5Betting({isClear: false});
        const betAmount = this.state.betAmount;
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds,
            }
            await this.props.addLotto5Betting(data);
            !isAddToCart && this.submitBetNow();

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: "",
                betMessage: null
            })
        }
    }

    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }

    setBettingStatusByTimer = (timerSecond) => {
        if (timerSecond <= 9 && timerSecond > 0) {
            this.setState({classCountDown: "total-result-render-count-warning"})
            this.props.setBetStatus({isStopBet: true})
        }
    }

    countingSecond = (count) => {
        if (this.props.isStopRender) {
            if (this.props.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                if (count >= 10) {
                    this.setState({classCountDown: "total-result-render-count"})
                } else if (count < 10 && count > 0) {
                    this.playAudio(countdownAudio);
                    if (count === 9) {
                        this.setBettingStatusByTimer(count);
                    }
                } else if (count === 0) {
                    if (this.drawingTime) {
                        clearInterval(this.drawingTime);
                    }
                    this.props.clearCart();
                    this.playAudio(goodLuckAudio)
                    this.props.setRenderResultStatus({isStopRender: false});
                    this.props.fetchUserBalance()
                    this.setState({classCountDown: "total-result-render-count-total"})
                }

            }
        }
    }

    getResultDrawing = () => {
        this.props.fetchLotto5DrawingResult()
            .then(() => this.getReleaseNum()).catch(() => {
            this.setState({allNumbersDrawing: []});
        })
    }

    getReleaseNum = async () => {
        let {indexNumber, countDownTimer} = this.props;
        if (countDownTimer > 0) countDownTimer = 0;
        let count = Math.abs(countDownTimer);
        if (count <= 3) {
            this.showLoading = setTimeout(() => {
                this.drawResult(0);
                clearTimeout(this.showLoading)
            }, 3000);
            return false;
        } else if (indexNumber <= 20 && indexNumber >= 0) {
            this.drawResult(indexNumber);
        }
    }

    drawResult = (indexNumber) => {
        this.setState({allNumbersDrawing: []});
        let {totalResult, allNumbersDrawing} = getResult(indexNumber, this.props.oldResult)

        this.setState({
                allNumbersDrawing: allNumbersDrawing,
                indexResultItem: indexNumber,
                totalResult: totalResult,
                classNameNumberDraw: "show-number-draw",
                classNameLogoRender: "hide-logo-render"
            }, () => this.showResultRender()
        );

    }


    showResultRender = () => {

        this.random && clearInterval(this.random);
        this.jackpotInv && clearInterval(this.jackpotInv);
        let num = 0;
        if (this.props.oldResult?.jackpot > 1) {
            this.jackpotInv = setInterval(() => {
                if (num >= 5) num = 0;
                num += 1;
                this.setState({
                    jackpot: num,
                });
            }, 800);
        } else {
            this.setState({
                jackpot: 0,
            });
        }

        this.setState({
            classNameNumberDraw: "show-number-draw",
            classNameLogoRender: "hide-logo-render"
        })
        let countSound = 1;
        this.drawingTime &&
        clearInterval(this.drawingTime);

        this.drawingTime = setInterval(() => {
                let indexResultItem = this.state.indexResultItem + 1;
                if (indexResultItem === 21) {
                    this.drawingTime && clearInterval(this.drawingTime);
                    this.setWinLose(indexResultItem);
                } else if (indexResultItem === 22) {
                } else {
                    countSound++;
                    if (countSound % 2 === 0) {
                        let resultItem = 1
                        this.random && clearInterval(this.random)
                        this.random = setInterval(() => {
                            if (resultItem === 81) {
                                resultItem = 1
                            }
                            this.setState({resultItem: resultItem++})
                        }, 10);
                        this.startDrawing && clearTimeout(this.startDrawing)
                        this.startDrawing = setTimeout(() => {
                            this.random && clearInterval(this.random)
                            let {
                                totalResult,
                                allNumbersDrawing,
                                resultItem
                            } = getResult(indexResultItem, this.props.oldResult);
                            this.playAudio(stopDrawAudio);
                            this.setState({
                                timeCountLotto5: -1,
                                resultItem: resultItem,
                                allNumbersDrawing: allNumbersDrawing,
                                indexResultItem: indexResultItem,
                                totalResult: totalResult,
                                classNameNumberDraw: "show-number-draw"
                            }, () => clearTimeout(this.startDrawing))
                        }, 1000);
                    }
                }
            }, 1000
        );
    }

    onShowDetail = (item) => {
        this.setState({
            isShowRecentBet: true,
            recentBetDetail: item
        });
    }

    onCloseDetail = () => {
        this.setState({
            isShowRecentBet: false,
            recentBetDetail: null
        });
    }

    afterPrint = () => {
        this.props.fetchLotto5DrawingResult().then(response => {
            this.setState({
                timeCountLotto5: response.data.data.countDownTimer
            });
        })
    }

    onDrawStatusChange = (currentDraw) => {
        if (currentDraw.status !== this.state.status) {
            if (currentDraw.status === "OPEN" && this.state.status) {
                this.refreshData();
            }
            if (currentDraw.status === "RELEASE") this.getResultDrawing()
            this.setState({status: currentDraw.status});
            this.verifyCurrentDraw(currentDraw.drawCode)
        }
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto5") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }

    playAudio = (src) => {
        const {audio} = this.props;
        if (audio && !audio.isMute) playAudio(src);
    };


    render() {
        const {
            platform, t, oldResult, countDownTimer, newResult, lastResult: {
                result,
                underOverCode,
                oddEvenCode,
                number,
                drawLabel
            },
            config: {
                lotto5Config
            }
        } = this.props;

        const {
            classNameNumberDraw,
            classNameLogoRender,
            indexResultItem,
            resultItem,
            winUO,
            winOE,
            winRange,
            isShowRecentBet,
            recentBetDetail,
            isShowValidateBalance,
            allNumbersDrawing,
            totalResult,
            classCountDown,
            jackpot
        } = this.state;


        const listOverUnder = isOddEven ? lotto5Config.overUnder : lotto5Config.overUnder.filter(e => !isOE(e.code));
        const getWidthItem = listOverUnder.length > 2 ? "w-25" : "w-50";

        const jp = oldResult?.jackpot;
        let platformType = env.PLATFORM_TYPE;

        return <div className="row col no-padding-div">
            <div className="col-6 no-padding-div pad-r">
                <div className="lotto5-dispaly-result">

                    <div className={"lotto5-total lotto5-header-" + platform + " " + pfType999Color}>

                        {
                            platform === "KPW" ? <div className="lotto5-row padding-h">
                                <label
                                    className="label-title KPW-title">{drawLabel}</label>
                                <p className="total-result" id="total_result"
                                >{result}</p>
                            </div> : <div className="row align-items-lg-end">
                                {(platform !== "888" && !pfType999) && <img className="col-6 no-padding-div"
                                                                            src={require("../../assets/images/banner/banner_lotto5.svg")}
                                                                            alt="///"/>}
                                {result &&
                                    <div
                                        className={`no-padding-div ${(platform !== "888" && !pfType999) ? "col-6" : "col d-flex align-items-center justify-content-center"}`}>
                                        <div className="col-6 p-0">
                                            {platform === "888" &&
                                                <img src={require("../../assets/images/banner/lottery5_banner.png")}
                                                     alt=""
                                                     className="img-lotto5"/>}
                                            {pfType999 &&
                                                <img
                                                    src={require(`../../assets/images/banner/lottery5_banner_${platformType}.png`)}
                                                    alt=""
                                                    className="img-lotto5"/>}
                                        </div>
                                        <div className="col-6 p-0 d-flex justify-content-between align-items-center">
                                            <label className="label-title ml-2">{drawLabel}</label>
                                            {
                                                isOddEven ? <p className="total-result mr-2" id="total_result"
                                                    >{t("labels:betPrefix." + underOverCode) + "  |   " + t("labels:betPrefix." + oddEvenCode) + "  |   " + t("labels:betPrefix." + number) + "   |   " + result}</p>
                                                    : <p className="total-result mr-2" id="total_result"
                                                    >{t("labels:betPrefix." + underOverCode) + "  |   " + t("labels:betPrefix." + number) + "   |   " + result}</p>
                                            }
                                        </div>

                                    </div>}

                            </div>
                        }

                    </div>

                    <div className="lotto5-content-result">
                        <table
                            id="keno_result_post"
                            border="1"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                        >
                            <tbody>
                            <ResultContentList
                                allNumbersDrawing={allNumbersDrawing}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-6 no-padding-div pad-l">
                <div className="lotto5-play-right">
                    <div className="row col-12 no-padding-div">
                        <div className="col-5 no-padding-div lotto5-count-down">
                            <div
                                className={"loading-gif d-flex align-items-center justify-content-center loading-gif-" + platform + " " + pfType999Color}>
                                <LoadingGif/>
                            </div>
                        </div>
                        <div className="col-7 no-padding-div header-text">
                            <div
                                className={` border-l bet-period-timer lotto5-header-${platform} ${pfType999Color} lotto5-header-draw-${platform}`}>

                                <p id="period-before">{newResult !== null ? newResult.drawLabel : t("labels:upcoming_draw")}</p>
                                <p id="timer-display"><DatetimeContainer/></p>
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 no-padding-div border-l">
                        <div className="col-5 no-padding-div">
                            <CountDownTimer
                                totalResult={totalResult}
                                classCountDown={classCountDown}
                                countDownTimer={countDownTimer}
                                tenSecondCallback={this.countingSecond}
                                countClass="total-result-render lotto5-total-result-render "/>
                            {
                                indexResultItem > 20 || indexResultItem === 0
                                    ? <div className="result-time-countup-render bg-transparent"/>
                                    : <div
                                        className="result-time-countup-render">{indexResultItem}</div>
                            }
                        </div>
                        <div className="col-7 no-padding-div">
                            <label
                                className="under-over block-header">{isOddEven ? t("labels:keno.underOverOddEven") : t("labels:keno.underOver")}</label>
                        </div>
                    </div>
                    <div className="row col-12 no-padding-div">
                        <div className="col-5 no-padding-div">
                            <div className="random-play">
                                <div className="blgo-random-number">
                                    <div
                                        className={classNameNumberDraw}>{resultItem}</div>
                                    <div
                                        className={classNameLogoRender + " lotto5-show-logo-render"}>
                                        <img
                                            src={require(`../../assets/images/logo/${platformType}.png`)}
                                            alt="..."/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7 no-padding-div">
                            <div className="over-under-odd-even">
                                <div className="group-play-box">
                                    {
                                        listOverUnder.map((item, i) => {
                                                return <div
                                                    className={`box-bet box-bet-${platform}-${item.code} bet-color-${platform} ${getWidthItem}`}
                                                    key={i}
                                                    onClick={isDisabledODEO ? null : () => this.onBetting(item)}>
                                                    <div id={"ou" + item.code}
                                                         className="lotto5-bet">
                                                        <div
                                                            className={`type d-flex align-items-center justify-content-center ${item.code} OU-${item.code === winUO ? item.code : ""} OE-${item.code === winOE ? item.code : ""} bg-OU-${item.code}`}>
                                                            <div>{t("labels:betPrefix.S_" + getShortBetType(item.code))}</div>
                                                        </div>
                                                        <div
                                                            className={`d-flex justify-content-center align-items-center under-over-label-wrapper bg-range-${item.code}`}>
                                                            <div>
                                                                {!isOE(item.code) ?
                                                                    <p className="range-v2">{item.rangeNumber}</p> : <>
                                                                        <p className="range-v2">{getOECode(item.code)}</p>
                                                                    </>}
                                                                <p className="odds-v2 mt-5">{item.value}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 no-padding-div">
                        <label
                            className="under-over-label block-header">{t("labels:keno.range")}</label>
                    </div>
                    <div className="row col-12 no-padding-div">

                        <div className="over-under-odd-even range-type">
                            <div className="group-play-box">
                                {
                                    lotto5Config.ranges.map((item, i) => {
                                        const isActive = (jackpot === i + 1) && oldResult;
                                        const isWin = item.code === winRange;
                                        const showJackpot = isWin && isActive;
                                        const winClass = isWin ? "win-" + item.code : "";
                                        let winCl = isWin ?
                                            isActive ? "win-bg-animate" : winClass
                                            : "";


                                        return <div
                                            className={"box-bet box-bet-" + platform + "-" + i + " bet-color-" + platform + " border-r" + platform}
                                            key={i}
                                            onClick={() => this.onBetting(item)}>
                                            <div id={"range" + item.elementId}
                                                 className="lotto5-bet">
                                                <div
                                                    className={`p-2 border-b ${isActive && !isWin ? "bg-win" : ""} ${winCl}`}>
                                                    <p className={"type-rage " + winClass}>{item.name}</p>
                                                </div>
                                                <div
                                                    className="jackpot-wrapper d-flex justify-content-center align-items-center">
                                                    {showJackpot && < >
                                                        <img className="jackpot-v2-img position-absolute"
                                                             src={require(`../../assets/images/jackpot/v2/${jp}.png`)}
                                                             alt="__"/>
                                                        <img
                                                            className="jackpot-v2-img position-absolute win-animate-border"
                                                            src={require('../../assets/images/jackpot/v2/border-jackpot.png')}
                                                            alt="___"/>
                                                    </>}
                                                    <div>
                                                        <p className="range-v2">{item.rangeNumber}</p>
                                                        <p className="odds-v2">{item.value}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <SweetAlert
                        title=""
                        show={isShowRecentBet}
                        onConfirm={this.onCloseDetail}
                        confirmBtnText={t("labels:common.close")}
                        showCancel={false}
                        btnSize="lg">
                        {
                            recentBetDetail &&
                            <RecentBetDetailDialog recentBetDetail={recentBetDetail}/>
                        }
                    </SweetAlert>

                    <SweetAlert
                        title="Balance"
                        show={isShowValidateBalance}
                        onConfirm={this.onCloseValidateBalance}
                        confirmBtnText={t("labels:common.okay")}
                        showCancel={false}
                        btnSize="lg"
                        confirmBtnBsStyle="warning"
                    />

                    <BettingDialog changeBetAmount={this.changeBetAmount}
                                   clickAmountBetBtn={this.clickAmountBetBtn}
                                   onConfirmBettingLotto5={this.onConfirmBettingLotto5}
                                   resetDefaultAmount={this.resetDefaultAmount}
                                   onCancelBetting={this.onCancelBetting}
                                   {...this.state}
                                   {...this.props}/>


                </div>
            </div>
        </div>
    }
}


const mapStateToProps = state => ({
    ...state.lotto5.draw,
    lotto5Config: state.lotto5.lotto5Config,
    config: state.config
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatusLotto5(payload)),
    setAudio: payload => dispatch(setAudio(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    fetchListLotto5Betting: payload => dispatch(fetchListLotto5Betting(payload)),
    addLotto5Betting: payload => dispatch(addLotto5Betting(payload)),
    deleteLotto5Betting: payload => dispatch(deleteLotto5Betting(payload)),
    fetchLotto5RecentDraw: payload => dispatch(fetchLotto5RecentDraw(payload)),
    fetchLotto5DrawingResult: () => dispatch(fetchLotto5DrawingResult()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
    clearCart: () => dispatch(clearCartLotto5()),
    fetchStatistic5: () => dispatch(fetchStatistic5())
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawingResult))
